body {
  background-image: url("../../img/bg-color.png");
}

.form-container {
  background-color: #e0e0e0;
  padding: 40px 80px;
  border-radius: 8px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  max-width: 800px;
  margin: 40px auto;
  /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5), 0 6px 6px rgba(0, 0, 0, 0.23); */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2), 0 -10px 20px rgba(0, 0, 0, 0.2),
    10px 0 20px rgba(0, 0, 0, 0.2), -10px 0 20px rgba(0, 0, 0, 0.2);
}

.register-text {
  margin: 30px 0;
  font-family: "Nunito", sans-serif;
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  font-family: "Nunito", sans-serif;
}

input,
textarea,
input[type="email"],
select {
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none; /* Remove the default focus outline */
  background: #fff;
}

textarea {
  height: 180px;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="date"]:focus,
input[type="number"]:focus,
textarea:focus,
select:focus {
  border-color: #007bff;
  outline: none; /* Ensure the default focus outline is removed */
}

fieldset {
  margin-bottom: 20px;
  border: 3px solid #ccc;
  border-radius: 4px;
  padding: 10px;
}

fieldset label {
  color: #808080;
}

legend {
  font-weight: bold;
  font-family: "Nunito", sans-serif;
}

button,
input[type="submit"] {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Nunito", sans-serif;
  transition: background-color 0.1s ease;
}

button:hover,
input[type="submit"]:hover {
  background-color: #0056b3;
  transform: scale(1.02);
}

.word-limit {
  font-size: 12px;
  color: #888;
  margin-top: -15px;
  margin-bottom: 20px;
}

.error {
  font-size: 12px;
  color: red;
  margin-top: -20px;
  margin-bottom: 20px;
}

/* Customize the date input icon color */
input[type="date"]::-webkit-calendar-picker-indicator {
  color: #ccc;
}

/* Remove the black outline when selecting a date */
input[type="date"]:focus::-webkit-calendar-picker-indicator {
  outline: none;
}

ul li {
  margin: 10px 0px 10px 40px;
  /* color: #2F4F4F; */
}

textarea {
  resize: none;
}

.is-required:after {
  content: "*";
  color: red;
  padding-left: 2px;
}

.required-field {
  color: red;
  /* margin-top: -10px; */
  margin: 10px 0px;
  margin-top: -20px;
  font-size: 13px;
  font-weight: 700;
  margin-left: 2px;
}

.cancel {
  padding: 0px 4px;
  font-size: 14px;
}

.cancel:hover {
  background-color: red;
}

.paragraph-link-style {
  cursor: pointer;
  color: #2f4f4f;
  font-weight: 900px;
  margin: 5px 0px;
}

.link-style {
  color: blue;
}

.experience {
  display: flex;
}

.experience:hover .cancel {
  background-color: red;
}

.exp-role {
  margin-bottom: 5px;
  font-weight: 600;
}

.org {
  margin-bottom: 3px;
}

.experience-date {
  font-size: 13px;
  margin-bottom: 15px;
}

.role-desc {
  font-size: 15px;
  margin: 10px 0px 40px 0px;
  line-height: 1.6em;
}

.edu-level {
  margin-bottom: 5px;
  font-weight: 600;
}

.study {
  margin-bottom: 3px;
}

.edu-date {
  font-size: 13px;
  margin-bottom: 25px;
}

.about-me-input,
.me-as-teacher-input,
.role-desc-input {
  line-height: 1.6em;
}
.space-cancel {
  margin-left: 30px;
}

.submit-style {
  width: 100%;
}

.working-here-wrapper {
  display: flex;
}

.working-here {
  margin-right: 5px;
}
