* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  body {
    font-family: "Nunito", sans-serif;
    margin: 0;
    background: "#fff";
    /* background-image: url("http://localhost:3000/images/bg-color.png") no-repeat center center/cover; */
    /* background-size: cover;
    background-repeat: no-repeat;
    background-position: center; */
    width: 100%;
    height: 100%;
  }
  
  
  