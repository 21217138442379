/* The Modal (background) */
.popup {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* Transparent background */
  /* background-color: rgba(0, 0, 0, 0.5);  */
  /* background-color: #fff; */
  background-color: #E6E6E6;
}

/* Modal Content - Styled as a Card */
.popup-content {
  background-color: white;
  margin: 10% auto;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 400px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1); /* Slightly raised card effect */
  line-height: 1.6rem;
  text-align: center;
}


.popup-content button {
  background-color: #007bff;
  display: block;
  margin: auto;
}

button:hover{
  background-color: #0056b3;
  transform: scale(1.02);
}

.thank-you{
  color: green;
  font-weight: bold;
  line-height: 2rem;
}