.confirm-container {
    background-color: #e0e0e0;
    padding: 40px 80px;
    border-radius: 8px;
    max-width: 800px;
    margin: 40px auto;
    /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5), 0 6px 6px rgba(0, 0, 0, 0.23); */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2), 0 -10px 20px rgba(0, 0, 0, 0.2),
      10px 0 20px rgba(0, 0, 0, 0.2), -10px 0 20px rgba(0, 0, 0, 0.2);
    word-wrap: break-word;  
  }

  input[type="checkbox"] {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 5px;
    border: 3px solid #0056b3;
  }

h1{
  color: black;
  text-align: center;
  margin: 30px;
}

h3 {
    margin-bottom: 15px;
}
.jj{
  width: 400px;
}

ul{
    margin-bottom: 40px;
}

.edu {
  margin-bottom: 5px;
}

.edu-insti{
    margin-bottom: 3px;
    font-size: 16px;
   }

  .role{
    margin-bottom: 5px;
  }
 
   .organization{
     margin-bottom: 3px;
   }
 
   .dates {
     font-size: 13px;
     margin-bottom: 10px;

   }

   .role-description{
     font-size: 15px;
     margin: 10px 0px 40px 0px;
     line-height: 1.6em;
   }

   .edu-dates{
    font-size: 13px;
   }
 
   .space-cancel{
     margin-left: 30px;
   }

   .about{
    margin-bottom: 50px;
    font-size: 15px;
    line-height: 1.6em;
   }

   .about-label{
    margin-bottom: 5px;
   }

   .termsAndConditions{
    display: flex;
    color: #007bff;
   }

   .submit-button{
    width: 100%;
    cursor: pointer;
    background-color: #007bff;
   }

   .submit-button:hover {
    transform: scale(1.02);
    background-color: #0056b3;
   }

   .edit-button{
    background-color: gray;
    cursor: pointer;
    width: 100%;
    margin: 10px 0px;
  }

   .edit-button:hover {
    background-color: #2F4F4F;
    transform: scale(1.02);
   }

   .submit-error{
    color: red;
    margin: auto;
    font-size: 20px;
    width: 80%;
    padding: 10px 0px 0px 0px;
   }

  